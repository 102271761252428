import * as React from 'react';
import { Container } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import Scanning from '../pages/ScanningBarcode';
import Verify from '../pages/Verify';
import Home from '../pages/Home';
import ScanningNLISID from '../pages/ScanningNLISID';
import ScanningConsignment from '../pages/ScanningConsignment';

const Layout = () => {
    return (
        <Container
            sx={{
                mt: '64px',
                mb: '24px',
            }}
        >
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/barcode' element={<Scanning />} />
                <Route path='/nlisid' element={<ScanningNLISID />} />
                <Route path='/verify' element={<Verify />} />
                <Route path='/consignment' element={<ScanningConsignment />} />
            </Routes>
        </Container>
    );
};

export default Layout;
