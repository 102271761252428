import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Footer } from 'react-component-utils';

import Layout from './Layout';
import Header from '../components/Header/Header';
import FallbackErrorContent from '../components/FallbackErrorContent/FallbackErrorContent';

function App() {
    return (
        <>
            <Router>
                <ErrorBoundary FallbackComponent={FallbackErrorContent}>
                    <Header />
                    <Layout />
                    <Footer />
                </ErrorBoundary>
            </Router>
        </>
    );
}

export default App;
