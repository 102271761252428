import * as React from 'react';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

/**
 * Home component is used to display the list of scan options
 */
const Home: React.FC = () => {
    return (
        <Box
            sx={{
                marginTop: '64px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                height: '50vh',
                width: '100%',
                gap: '24px',
            }}
        >
            {/* <Button variant='contained' component={Link} to='/nlisid'>
                Scan NLISID
            </Button> */}
            <Button variant='contained' component={Link} to='/barcode'>
                Scan Barcode
            </Button>
            {/* <Button variant='contained' component={Link} to='/consignment'>
                Scan Consignment
            </Button> */}
        </Box>
    );
};

export default Home;
