import React from 'react';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { GS1LinkSet, GS1LinkType, DLRResponse, GS1Link, MimeType, publicAPI } from 'react-component-utils';
import DialogVerifierServices from '../components/CustomDialog/VerifierServices/VerifierServices';
import { ScreenStatus } from '../models/common';
import { CustomDialog } from 'react-component-utils';

const mockConsignments = ['C-101507453'];

/**
 * Consignment component is used to scan the eNVD Consignment ID and show information about the eNVD Consignment ID
 */
const ScanningConsignment = () => {
    const [consignmentId, setConsignment] = React.useState<string>('');
    const [passportLink, setPassportLink] = React.useState<GS1Link | null>(null);
    const [openDialogVerifier, setOpenDialogVerifier] = React.useState<boolean>(false);
    const [verifierServices, setVerifierServices] = React.useState<GS1Link[]>([]);
    const [currentScreen, setCurrentScreen] = React.useState<string>(ScreenStatus.INITIAL_SCAN);
    const [consignmentTextDisplay, setConsignmentTextDisplay] = React.useState<string>('');
    const [openDialogErrorCode, setOpenDialogErrorCode] = React.useState<boolean>(false);

    /**
     * handle change consignment id when user input
     */
    const handleChangeConsignmentId = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConsignment(e.target.value);
    };

    /**
     * handle scan consignment code when user click scan button
     */
    const handleSubmitConsignmentId = (e: React.SyntheticEvent) => {
        e.preventDefault();
        setConsignmentTextDisplay(consignmentId);
        setCurrentScreen(ScreenStatus.LOADING_FETCHING_DATA);
        handleConsignmentData();
    };

    /**
     * check mock data and fetch DLR data
     */
    const handleConsignmentData = async () => {
        if (!mockConsignments.includes(consignmentId)) {
            setOpenDialogErrorCode(true);
            setCurrentScreen(ScreenStatus.INITIAL_SCAN);
            return;
        }

        const data = await fetchConsignment(consignmentId);
        await handleDLRData(data[0].digitalLinkResolver);
    };

    /**
     * fetch Consignment data
     * ! This is mock data
     * TODO replace with api call to get Consignment data
     */
    const fetchConsignment = async (consignmentId: string) => {
        const response = await Promise.resolve([
            {
                id: 'C-100657321',
                number: 1,
                status: 'active',
                movementDate: '2023-10-01T00:00:00.000Z',
                movementTime: '2023-10-01T00:00:00.000Z',
                species: '',
                createdBy: '',
                updatedAt: '2023-10-01T00:00:00.000Z',
                heads: 1,
                pdfUrl: '',
                destination: {
                    address: {
                        line1: '123 Main Street',
                        postcode: '12345',
                        state: 'CA',
                        town: 'Example Town',
                    },
                    name: 'Destination Name',
                    pic: 'PIC Name',
                },
                digitalLinkResolver: `${process.env.REACT_APP_FEEDLOT_DLR_API_URL}/consignment_id/${consignmentId}?linkType=all`,
            },
        ]);
        return response;
    };

    /**
     * handle scan DLR data to get passport link and verifier services
     * @param url this url api to get data DRL
     */
    const handleDLRData = async (url: string) => {
        try {
            const data = await publicAPI.get<DLRResponse>(url);
            const passports = (data['linkset'] as GS1LinkSet[]).find(
                (linkSet: GS1LinkSet) => linkSet[GS1LinkType.certificationInfo],
            )![GS1LinkType.certificationInfo];

            const services = (data['linkset'] as GS1LinkSet[]).find(
                (linkSet: GS1LinkSet) => linkSet[GS1LinkType.verificationService],
            )![GS1LinkType.verificationService];

            const passport = passports!.find((item) => item.type === MimeType.applicationJson);
            setPassportLink(passport!);
            setVerifierServices(services!);
            setCurrentScreen(ScreenStatus.RESPONSE_DATA_SUCCESS);
        } catch (err) {
            setCurrentScreen(ScreenStatus.RESPONSE_DATA_FAIL);
        }
    };

    /**
     * render component following state of screen changes
     */
    const renderComponent = () => {
        return React.useMemo(() => {
            switch (currentScreen) {
                case ScreenStatus.LOADING_FETCHING_DATA:
                    return <CircularProgress size={24} />;
                case ScreenStatus.RESPONSE_DATA_SUCCESS:
                    return (
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography>Information found for the Consignment ID: {consignmentTextDisplay}</Typography>
                            <Button onClick={() => setOpenDialogVerifier(true)}>{passportLink?.title}</Button>
                        </Box>
                    );
                default:
                    return <></>;
            }
        }, [currentScreen]);
    };

    /**
     * handle close dialog verifier
     */
    const handleCloseDialogVerifier = () => {
        setOpenDialogVerifier(false);
    };

    /**
     * handle close dialog when code not found
     */
    const handleCloseDialogErrorFetchProductData = () => {
        setOpenDialogErrorCode(false);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: { xs: 'auto', md: '80vh' },
                gap: 2, //16px gap
            }}
            component={'form'}
            onSubmit={handleSubmitConsignmentId}
        >
            <TextField
                value={consignmentId}
                label='ConsignmentID'
                variant='standard'
                onChange={handleChangeConsignmentId}
            />
            <Button type='submit' variant='contained' disabled={consignmentId === '' ? true : false}>
                Scan
            </Button>

            {/* Show information when have input and scan success */}
            {renderComponent()}

            {openDialogVerifier && passportLink && (
                <DialogVerifierServices
                    open={openDialogVerifier}
                    close={handleCloseDialogVerifier}
                    data={{ passportLink, verifierServices }}
                />
            )}

            {openDialogErrorCode && (
                <CustomDialog
                    title='Product not found'
                    open={openDialogErrorCode}
                    onClose={handleCloseDialogErrorFetchProductData}
                />
            )}
        </Box>
    );
};

export default ScanningConsignment;
