import React from 'react';
import { CustomDialog, GS1Link, defaultVerificationServiceLink } from 'react-component-utils';
import { useNavigate } from 'react-router';

import Button from '@mui/material/Button';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

export type DialogVerifierServicesData = {
    passportLink: GS1Link;
    verifierServices: GS1Link[];
};

interface DialogVerifierServicesProps {
    open: boolean;
    data: DialogVerifierServicesData;
    close: () => void;
    onVerified?: () => void;
}

/**
 * DialogVerifierServices component is used to show the list of verifier services
 */

const DialogVerifierServices: React.FC<DialogVerifierServicesProps> = ({ open, close, data, onVerified }) => {
    const navigate = useNavigate();
    const [verifierServiceUrl, setVerifierServiceUrl] = React.useState<string>();
    const verifierServices = [...data.verifierServices, defaultVerificationServiceLink];

    /**
     * handle change value verifier service
     * @param e
     * @param value
     */
    const handleChangeVerifierService = (_e: React.ChangeEvent, value: string) => {
        setVerifierServiceUrl(verifierServices[+value].href);
    };

    /**
     * onClickVerify is used to verify the passport
     */
    const onClickVerify = () => {
        const passportUrl = data.passportLink.href;
        const query = encodeURIComponent(JSON.stringify({ payload: { uri: passportUrl } }));

        onVerified && onVerified();
        navigate('/verify?q=' + query, { state: { verifierServiceUrl } });
    };

    /**
     * dialogContent is used to render the content of dialog component
     */
    const dialogContent = {
        content: (
            <FormControl>
                <RadioGroup name='controlled-radio-buttons-group' onChange={handleChangeVerifierService}>
                    {verifierServices.map((item, idx) => (
                        <FormControlLabel key={idx} value={idx} control={<Radio />} label={item.title} />
                    ))}
                </RadioGroup>
            </FormControl>
        ),
        buttons: (
            <Button disabled={verifierServiceUrl ? false : true} onClick={() => onClickVerify()}>
                Verify
            </Button>
        ),
    };

    /**
     * dialogStyle is used to render the style of dialog component
     */
    const dialogStyle = {
        content: {
            width: '500px',
            maxWidth: '80%',
        },
    };

    return (
        <CustomDialog
            open={open}
            onClose={close}
            title='Verifier Services'
            buttons={dialogContent?.buttons}
            content={dialogContent?.content}
            style={dialogStyle}
        />
    );
};

export default React.memo(DialogVerifierServices);
