/**
 * replaceSpecialCharacters replaces all special characters in a string with an empty string
 */
export function replaceSpecialCharacters(str: string) {
    return str.replace(/[\W_]/gi, '');
}

/**
 * isValidStringPattern checks if a string matches a given pattern
 */
export function isValidStringPattern(str: string, length: number) {
    const regexPattern = new RegExp(`^[A-Z0-9]{${length}}$`);
    return regexPattern.test(str);
}
