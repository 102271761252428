import React from 'react';
import { Box } from '@mui/material';
import { VerifiableCredential } from '@vckit/core-types';
import { MessageText, Status } from 'react-component-utils';

import CredentialInfo from '../CredentialInfo/CredentialInfo';
import CredentialTabs from '../CredentialTabs/CredentialTabs';

const Credential = ({ credential }: { credential: VerifiableCredential }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                overflow: 'hidden',
                width: '100%',
            }}
        >
            <MessageText status={Status.success} text='Document has not been tampered' />
            <MessageText status={Status.success} text='Document has not been revoked' />
            <CredentialInfo credential={credential} />
            <CredentialTabs credential={credential} />
        </Box>
    );
};

export default Credential;
