import * as React from 'react';
import { Box, CircularProgress, Stack } from '@mui/material';
import {
    GS1LinkSet,
    GS1LinkType,
    DLRResponse,
    GS1Link,
    defaultVerificationServiceLink,
    MimeType,
    publicAPI,
    CustomDialog,
    Scanner,
    IScannerRef,
} from 'react-component-utils';
import { useNavigate } from 'react-router';
import { Html5QrcodeResult } from 'html5-qrcode';
import { GS1Response } from '../models/gs1';

const mockGTINs = ['09359502000034', '09359502000041', '09359502000010'];

/**
 * ScanningBarcode component is used for scan the barcode and show information about the barcode
 */
const ScanningBarcode = () => {
    const navigate = useNavigate();
    const [GTIN, setGTIN] = React.useState<string>('');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [openDialogErrorCode, setOpenDialogErrorCode] = React.useState<boolean>(false);
    const scannerRef = React.useRef<IScannerRef | null>(null);

    React.useEffect(() => {
        if (!GTIN) return;

        setIsLoading(true);
        handleDataGS1(GTIN);
    }, [GTIN]);

    /**
     * handle scan successfully barcode or data matrix
     */
    const onScanResult = (decodedText: string, result: Html5QrcodeResult) => {
        let text = '';

        switch (result?.result?.format?.formatName) {
            case 'DATA_MATRIX':
                text = decodedText.slice(2, 16);
                break;
            default:
                text = decodedText.length < 14 ? '0' + decodedText : decodedText;
                break;
        }

        setGTIN(text);
    };

    /**
     * handle scan barcode error
     */
    const onScanError = (error: unknown) => {
        error;
    };

    /**
     * handle data GS1
     * ! This is mock data
     * TODO replace with api call to get GS1 data
     */
    const handleDataGS1 = async (code: string) => {
        if (!mockGTINs.includes(code)) {
            setOpenDialogErrorCode(true);
            setGTIN('');
            return;
        }

        try {
            const productInfo = await fetchProductInfo(code);

            let url = productInfo.linkset[GS1LinkType.serviceInfo]![0].href;
            url = url + `/gtin/${code}?linkType=all`;
            await handleDataDRL(url);
        } catch (error) {
            console.log('There no link resolver');
        }
    };

    const fetchProductInfo = async (code: string) => {
        try {
            const params = { keys: [code], dlrUrl: process.env.REACT_APP_PROCESSOR_DLR_API_URL! };
            const data: GS1Response[] = await publicAPI.post(process.env.REACT_APP_GS1_API_PROXY_URL!, params);

            return data[0];
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    /**
     * handle DRL data
     * @param url this url api to get data DRL
     */
    const handleDataDRL = async (url: string) => {
        try {
            const data = await publicAPI.get<DLRResponse>(url);
            const passports = (data['linkset'] as GS1LinkSet[]).find(
                (linkSet: GS1LinkSet) => linkSet[GS1LinkType.certificationInfo],
            )![GS1LinkType.certificationInfo];

            const services = (data['linkset'] as GS1LinkSet[]).find(
                (linkSet: GS1LinkSet) => linkSet[GS1LinkType.verificationService],
            )![GS1LinkType.verificationService];

            const passport = passports!.find((item) => item.type === MimeType.applicationJson);
            redirectToVerifyPage(passport!, services!);
        } catch (err) {
            console.log('There no passport or verifier services');
        } finally {
            setIsLoading(false);
        }
    };

    /**
     * handle close dialog when code not found
     */
    const handleCloseDialogErrorFetchProductData = () => {
        setOpenDialogErrorCode(false);
    };

    const redirectToVerifyPage = (passportLink: GS1Link, verifierServices: GS1Link[]) => {
        let selectedVerifierService = defaultVerificationServiceLink.href;
        if (verifierServices.length < 1) {
            selectedVerifierService = verifierServices[0].href;
        }

        const passportUrl = passportLink.href;
        const query = encodeURIComponent(JSON.stringify({ payload: { uri: passportUrl } }));
        navigate('/verify?q=' + query, { state: { verifierServiceUrl: selectedVerifierService } });
        scannerRef.current?.closeQrCodeScanner();
    };

    return (
        <Box
            sx={{
                maxHeight: '500px',
                maxWidth: '500px',
                margin: 'auto',
                textAlign: 'center',
            }}
        >
            <Scanner
                ref={scannerRef}
                fps={10}
                disableFlip={false}
                qrCodeSuccessCallback={onScanResult}
                qrCodeErrorCallback={onScanError}
            />
            {GTIN && (
                <>
                    {isLoading && (
                        <Stack>
                            <CircularProgress sx={{ margin: 'auto', marginTop: '20px' }} size={24} />
                        </Stack>
                    )}
                </>
            )}

            {openDialogErrorCode && (
                <CustomDialog
                    title='Product not found'
                    open={openDialogErrorCode}
                    onClose={handleCloseDialogErrorFetchProductData}
                />
            )}
        </Box>
    );
};

export default React.memo(ScanningBarcode);
