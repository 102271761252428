import React, { useState, useCallback, useEffect } from 'react';
import { decryptString } from '@govtechsg/oa-encryption';
import { IVerifyResult, VerifiableCredential } from '@vckit/core-types';
import { useLocation } from 'react-router';
import { computeEntryHash } from '@veramo/utils';
import {
    LoadingWithText,
    MessageText,
    defaultVerificationServiceLink,
    Status,
    privateAPI,
    publicAPI,
} from 'react-component-utils';

import BackButtonLayout from '../layouts/BackButtonLayout/BackButtonLayout';
import Credential from '../components/Credential/Credential';

enum PassportStatus {
    'LOADING_FETCHING_PASSPORT' = 'LOADING_FETCHING_PASSPORT',
    'LOADING_PASSPORT_VERIFIED' = 'LOADING_PASSPORT_VERIFIED',
    'ERROR' = 'ERROR',
    'VERIFY_SUCCESS' = 'VERIFY_SUCCESS',
}

type VerifyError = { message: string; [k: string]: string };

/**
 *
 * Verify component is used to verify the passport
 */
const Verify = () => {
    const { search } = useLocation();

    const [currentScreen, setCurrentScreen] = useState(PassportStatus.LOADING_FETCHING_PASSPORT);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [credential, setCredential] = useState<VerifiableCredential | undefined>(undefined);

    useEffect(() => {
        fetchEncryptedVC();
    }, [search]);

    useEffect(() => {
        if (credential) {
            setCurrentScreen(PassportStatus.LOADING_PASSPORT_VERIFIED);
            verifyCredential(credential);
        }
    }, [credential]);

    /**
     * fetchEncryptedVC function is used to fetch the encrypted VC and decrypt it
     */
    const fetchEncryptedVC = useCallback(async () => {
        const queryParams = new URLSearchParams(search);
        const query = queryParams.get('q') || '';

        if (!query) {
            displayErrorUI();
            return;
        }

        const payload = JSON.parse(decodeURIComponent(query));
        const { uri, key, hash } = payload.payload;

        try {
            const encryptedCredential: any = await publicAPI.get(uri);

            if (encryptedCredential?.credentialSubject) {
                setCredential(encryptedCredential);
                return;
            }

            // TODO: using the node-forge to decrypt the encryptedCredential
            const stringifyVC = decryptString({
                ...encryptedCredential,
                key: key,
                type: 'OPEN-ATTESTATION-TYPE-1',
            });

            const vc = JSON.parse(stringifyVC);
            const credentialHash = computeEntryHash(vc);

            if (credentialHash !== hash) {
                displayErrorUI();
                return;
            }

            setCredential(vc);
        } catch (error) {
            displayErrorUI();
        }
    }, [search]);

    const displayErrorUI = () => {
        setCurrentScreen(PassportStatus.ERROR);
        setErrorMessages(['Something went wrong. Please try again.']);
    };

    /**
     * handle verify credential
     * @param passportInfo this is passport url
     */
    const verifyCredential = async (passportInfo: VerifiableCredential) => {
        try {
            const params = {
                credential: passportInfo,
                fetchRemoteContexts: true,
                policies: {
                    credentialStatus: true,
                },
            };

            const verifyUrl = defaultVerificationServiceLink.href;
            privateAPI.setBearerTokenAuthorizationHeaders('kh14g04piduv');
            const data = await privateAPI.post<IVerifyResult>(verifyUrl, params);
            handleResultVerifyCredential(data);
        } catch (error) {
            displayErrorUI();
        }
    };

    /**
     * handle result verify credential
     * @param verifyResult
     */
    const handleResultVerifyCredential = (verifyResult: IVerifyResult) => {
        if (verifyResult?.verified) {
            setCurrentScreen(PassportStatus.VERIFY_SUCCESS);
            return;
        }

        if (verifyResult?.error?.message) {
            setCurrentScreen(PassportStatus.ERROR);
            setErrorMessages([verifyResult.error.message]);
            return;
        }
        if (verifyResult?.results) {
            const errorMessages = (verifyResult?.results || []).map(
                (result: { error: VerifyError }) => result.error?.message || '',
            );
            setErrorMessages(errorMessages);
            setCurrentScreen(PassportStatus.ERROR);
            return;
        }
        displayErrorUI();
    };

    /*
     * renderPageFollowStatus function is used to render the page follow the status
     */
    const renderPageFollowStatus = () => {
        return React.useMemo(() => {
            switch (currentScreen) {
                case PassportStatus.LOADING_FETCHING_PASSPORT:
                    return <LoadingWithText text='Fetching the credential' />;
                case PassportStatus.LOADING_PASSPORT_VERIFIED:
                    return <LoadingWithText text='Verifying the credential' />;
                case PassportStatus.VERIFY_SUCCESS:
                    if (!credential) return null;
                    return (
                        <BackButtonLayout>
                            <Credential credential={credential} />
                        </BackButtonLayout>
                    );
                default:
                    return (
                        <BackButtonLayout>
                            {errorMessages.map((message, idx) => {
                                return <MessageText status={Status.error} text={message} key={idx} />;
                            })}
                        </BackButtonLayout>
                    );
            }
        }, [currentScreen]);
    };

    return renderPageFollowStatus();
};

export default Verify;
